<template>
  <div class="page-layout page-redirect">
    <Loading id="appLoading" :is-show="true" :text="textprocess" />
    <div>
      <main></main>
      <!-- Modal -->
      <a-modal
        v-model="isShowModal"
        class="process-modal"
        centered
        :width="650"
        :closable="false"
      >
        <template slot="footer">
          <a-button type="primary" @click="handlemodalCancelError">
            Close
          </a-button>
        </template>
        <div>
          <div class="title-modal">
            <span style="color: #faad14; margin-right: 16px">
              <a-icon
                v-show="idModal == 'error'"
                style="color: #faad14"
                type="exclamation-circle"
              />
              <a-icon
                v-show="idModal == 'success'"
                style="color: #52c41a"
                type="check-circle"
              />
            </span>
            <span>{{ titleModal }}</span>
          </div>
          <div class="content-modal">
            <p>{{ contentModal }}</p>
            <div v-if="errtransaction_id" style="font-size: 12px">
              Transaction ID : {{ errtransaction_id }}
            </div>
          </div>
        </div>
      </a-modal>
      <div
        id="modal-error"
        class="ant-modal-root ant-modal-confirm ant-modal-confirm-error ant-modal-error ant-modal-confirm ant-modal-confirm-error ant-modal-error hideModal"
      >
        <div class="ant-modal-mask"></div>
        <div
          tabindex="-1"
          role="dialog"
          class="ant-modal-wrap ant-modal-centered"
        >
          <div role="document" class="ant-modal">
            <div class="ant-modal-content">
              <div class="ant-modal-body">
                <div class="ant-modal-confirm-body-wrapper">
                  <div class="ant-modal-confirm-body">
                    <i
                      aria-label="icon: close-circle"
                      class="anticon anticon-close-circle"
                    >
                      <svg
                        viewBox="64 64 896 896"
                        data-icon="close-circle"
                        width="1em"
                        height="1em"
                        fill="currentColor"
                        aria-hidden="true"
                        focusable="false"
                        class=""
                      >
                        <path
                          d="M685.4 354.8c0-4.4-3.6-8-8-8l-66 .3L512 465.6l-99.3-118.4-66.1-.3c-4.4 0-8 3.5-8 8 0 1.9.7 3.7 1.9 5.2l130.1 155L340.5 670a8.32 8.32 0 0 0-1.9 5.2c0 4.4 3.6 8 8 8l66.1-.3L512 564.4l99.3 118.4 66 .3c4.4 0 8-3.5 8-8 0-1.9-.7-3.7-1.9-5.2L553.5 515l130.1-155c1.2-1.4 1.8-3.3 1.8-5.2z"
                        ></path>
                        <path
                          d="M512 65C264.6 65 64 265.6 64 513s200.6 448 448 448 448-200.6 448-448S759.4 65 512 65zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"
                        ></path>
                      </svg>
                    </i>
                    <span id="modal-title" class="ant-modal-confirm-title"
                      >You don't have a permission</span
                    >
                    <div id="modal-content" class="ant-modal-confirm-content">
                      Please login with another account.
                    </div>
                  </div>
                  <div class="ant-modal-confirm-btns">
                    <button
                      type="button"
                      class="ant-btn ant-btn-primary"
                      @click="handlemodalCancelError"
                    >
                      <span>Close</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div
              tabindex="0"
              aria-hidden="true"
              style="width: 0px; height: 0px; overflow: hidden"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as Msal from "@azure/msal-browser";
import CommonMixin from "@/mixin/CommonMixin";
import { config } from "@/config/bzbsconfig.js";
import Cache from "@/helper/Cache";
import redirectmsAPI from "@/core/Page/RedirectmsAPI";
import Loading from "@/components/Loading/Loading.vue";

const myMSAL = new Msal.PublicClientApplication(config.msalConfig);
myMSAL
  .handleRedirectPromise()
  .then((response) => {
    if (response) {
      if (
        response.idTokenClaims["tfp"].toUpperCase() ===
        config.b2cPolicies.names.signUpSignIn.toUpperCase()
      ) {
        document.cookie = "msToken=" + response.accessToken;
        document.cookie =
          "msAccount=" + JSON.stringify(response.account.idTokenClaims);
        document.cookie = "msEmail=" + response.account.idTokenClaims.emails[0];
        if (response.state) {
          let text = response.state.toLowerCase();
          let position = text.search("code");
          if (position >= 1) {
            document.cookie = "inViteCode=" + text;
          }
        }
        handleResponse(response);
      }
    }
  })
  .catch((error) => {
    console.log(error);
    let text = error.message.toLowerCase();
    let iserrorback = text.includes("access_denied");
    if (iserrorback) {
      location.reload();
    }
  });
function selectAccount() {
  const currentAccounts = myMSAL.getAllAccounts();
  if (currentAccounts.length < 1) {
    return;
  } else if (currentAccounts.length > 1) {
    const accounts = currentAccounts.filter(
      (account) =>
        account.homeAccountId
          .toUpperCase()
          .includes(config.b2cPolicies.names.signUpSignIn.toUpperCase()) &&
        account.idTokenClaims.iss
          .toUpperCase()
          .includes(config.b2cPolicies.authorityDomain.toUpperCase()) &&
        account.idTokenClaims.aud === config.msalConfig.auth.clientId
    );

    if (accounts.length > 1) {
      if (
        accounts.every(
          (account) => account.localAccountId != accounts[0].localAccountId
        )
      ) {
        signOut();
      }
    }
  }
}
selectAccount();
async function handleResponse(res) {
  if (res !== null) {
    if (Cache.get("msStatus") == "signin") {
      if (Cache.get("msToken")) {
        var inviteobj = Cache.get("inViteCode");
        const raraminviteobj = new URLSearchParams(inviteobj);
        let id = "";
        let gotoUrl = "";
        if (raraminviteobj.has("code")) {
          id = raraminviteobj.get("code");
        }
        if (raraminviteobj.has("product_main_url")) {
          gotoUrl = raraminviteobj.get("product_main_url");
        }
        if (id) {
          callpostAccepInvitation(id, gotoUrl);
        } else {
          callGetCheckAccount();
        }
      } else {
        if (!Cache.get("loading")) {
          myMSAL.loginRedirect(config.loginRequest);
        }
      }
    }
  } else {
    selectAccount();
  }
}
function signOut() {
  const logoutRequest = {
    postLogoutRedirectUri: config.msalConfig.auth.redirectUri,
  };

  myMSAL.logoutRedirect(logoutRequest);
}
function callGetCheckAccount() {
  redirectmsAPI
    .getCheckAccount()
    .then((result) => {
      let data = result.data.result;
      document.cookie = "exAccount=" + JSON.stringify(data);
      window.location = window.location.origin + "/no-permission";
    })
    .catch((err) => {
      this.handleLoading(false);
      document.getElementById("modal-title").innerHTML =
        "Error " + err.response.data.error[0].code;
      document.getElementById("modal-content").innerHTML =
        err.response.data.error[0].message +
        "<br>(Transaction ID : " +
        err.response.data.transaction_id +
        ")";
      document.getElementById("modal-error").classList.remove("hideModal");
    });
}
function callpostAccepInvitation(code, gotoUrl) {
  let params = {
    code: code,
  };
  redirectmsAPI
    .postAccepInvitation(params)
    .then((result) => {
      if (result.data.is_success) {
        var resultInvite = JSON.stringify({
          err: false,
        });
        if (gotoUrl) {
          Cache.remove("inViteCode");
          let text = gotoUrl;
          let position = text.search("product-platform-external");
          if (position >= 0) {
            callGetCheckAccount();
          } else {
            window.location = gotoUrl;
          }
        } else {
          document.cookie = "resultInvite=" + resultInvite;
          window.location = window.location.origin + "/LoginInvite";
        }
      }
    })
    .catch((err) => {
      // this.handleLoading(false);
      var resultInvite = JSON.stringify({
        err: true,
        id: err.response.data.error[0].id,
        name: err.response.data.error[0].code,
        content: err.response.data.error[0].message,
        transaction_id: err.response.data.transaction_id,
      });
      document.cookie = "resultInvite=" + resultInvite;
      window.location = window.location.origin + "/LoginInvite";
    });
}

export default {
  name: "Redirectms",
  components: {
    Loading,
  },
  mixins: [CommonMixin],
  data() {
    return {
      isShowModal: false,
      errtransaction_id: "",
      titleModal: "",
      contentModal: "",
      idModal: "",
      textprocess: "",
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.textprocess = "Process SSO ...";
      this.handleLoading(true);
      myMSAL.loginRedirect(config.loginRequest);
    },

    handlemodalCancelError() {
      this.errtransaction_id = "";
      this.isShowModal = false;
      this.$router.push({ name: "Home" });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/_mixin.scss";
.hideModal {
  visibility: hidden;
  opacity: 0;
}
</style>
