import BzbsApi from "@/core/Http/BzbsApi.js";
import Cache from "@/helper/Cache.js";
// import { store } from "@/store";
import { config } from "@/config/bzbsconfig.js";

/**
 * @method getUserList
 */
export default {
  config: function () {
    // let cacheBzbsConfig = store.state.azureConfig;
    // return cacheBzbsConfig;
    return config;
  },
  token: function () {
    return Cache.get("msToken");
  },
  getUsePermission: function (params) {
    var url =
      this.config().apiBaseUrl +
      this.config().apiVersion +
      "/user_permission/get";
    return BzbsApi.connectBzbsGet(url, this.token(), params);
  },
  getValidateUse: function (params) {
    var url =
      this.config().apiBaseUrl +
      this.config().apiVersion +
      "/user/validate_invitation_code";
    return BzbsApi.connectBzbsGet(url, this.token(), params);
  },
};
