import Vue from "vue";
import Cache from "@/helper/Cache.js";
import Auth from "@/core/Account/service/Auth";
import Locale from "@/helper/locale/locale.js";

//Main key.
const KEY_COOKIE_AUTH = "Auth";

/* eslint-disable */
export default {
  config: function () {
    return Vue.bzbsConfig;
  },
  initial() {
    Vue.initFinished = false;
    Locale.callBlobVersion().then(() => {
      Locale.getLbl();
    });
    this.isLogin();
  },
  isLogin() {
    console.log(Cache.get(KEY_COOKIE_AUTH));
  },
  getAuth() {
    return Cache.get(KEY_COOKIE_AUTH);
  },
  setAuth(auth) {
    return Cache.set(KEY_COOKIE_AUTH, auth);
  },
  clearAuth() {
    return Cache.remove(KEY_COOKIE_AUTH);
  },
  clearCache() {
    Cache.remove("msToken");
    Cache.remove("loading");
    Cache.remove("msAccount");
    Cache.remove("msStatus");
    Cache.remove("msEmail");
    Cache.remove("menuList");
  },
  register(params) {
    return new Promise((resolve, reject) => {
      Auth.apiRegister(params)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  login(params) {
    return new Promise((resolve, reject) => {
      Auth.apiLogin(params)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  logout() {
    this.clearAuth();
    this.clearCache();
    // window.location.replace("/");
  },
  async loadFacebookSDK(d, s, id) {
    var js,
      fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) {
      return;
    }
    js = d.createElement(s);
    js.id = id;
    js.src = "https://connect.facebook.net/en_US/sdk.js";
    fjs.parentNode.insertBefore(js, fjs);
  },
  fbAsyncInit() {
    window.fbAsyncInit = () => {
      window.FB.init({
        appId: this.config().fbAppId, //You will need to change this 327544911989466
        cookie: true, // This is important, it's not enabled by default
        xfbml: true,
        version: "v13.0",
      });
    };
  },
  msAuthorize() {
    return `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${
      this.config().msClientId
    }&response_type=code&redirect_uri=${
      window.location.origin
    }/&response_mode=query&scope=openid%20offline_access%20https%3A%2F%2Fgraph.microsoft.com%2Fmail.read&state=12345`;
  },
};
