<template>
    <div></div>
</template>
<script>
import * as Msal from '@azure/msal-browser'
import { config } from '@/config/bzbsconfig.js'

const myMSAL = new Msal.PublicClientApplication(config.msalConfig)

export default {
    name: 'LogoutAd',
    created() {
        this.init()
    },
    methods: {
        init() {
            const d = new Date()
            d.setTime(d.getTime() + -1 * 24 * 60 * 60 * 1000)
            let expires = 'expires=' + d.toUTCString()
            document.cookie =
            'account=; max-age=-1;' + expires + ' path=/; SameSite=None; Secure'
            document.cookie =
            'msToken=; max-age=-1;' + expires + ' path=/; SameSite=None; Secure'
            
            document.cookie = 'logout=true; path=/; SameSite=None; Secure'
            const logoutRequest = {
            postLogoutRedirectUri: config.msalConfig.auth.postLogoutRedirectUri,
            }
            myMSAL.logoutRedirect(logoutRequest)
        },
    },
}
</script>