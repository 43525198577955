import axios from "axios";
import Vue from "vue";
import Cache from "@/helper/Cache.js";
import _ from "lodash";

/**
 * @method connectBzbsGet
 * @method connectBzbsPost
 * @method connectBzbsGetBlob
 */
export default {
  /**
   * Connect Buzzebees GET method
   * @param {string} url string url
   * @param {string} token (optional)
   * @param {object} data (optional)
   * @return Promise
   */
  language: function () {
    return Cache.get("BzbsLocale");
  },
  connectBzbsGet: function (url, token, data) {
    var header = {
      // 'App-Id': Vue.bzbsConfig.client_id,
    };
    if (data == null) {
      data = {};
    }
    // data.device_app_id = this.config().client_id;
    if (token != null) {
      header.Authorization = "Bearer " + token;
    }
    if (this.language() == "th") {
      header.Language = this.language();
    }
    return axios({
      method: "get",
      url: url,
      params: data,
      headers: header,
    });
  },

  /**
   * Connect Buzzebees POST method
   * @param {string} url string url
   * @param {string} token (optional)
   * @param {object} data parameter
   * @param {string} uploadKey (optional)
   * @param {object} uploadfile (optional)
   * @return Promise
   */
  connectBzbsPost: function (url, token, data, uploadKey, uploadfile) {
    var header = {
      "App-Id": Vue.bzbsConfig.client_id,
    };
    // data.device_app_id = this.config().client_id;
    var form = this.arrToFormData(data);
    if (uploadKey) form.append(uploadKey, uploadfile);

    if (token != null) {
      header.Authorization = "token " + token;
    }
    if (this.language() == "th") {
      header.Language = this.language();
    }
    return axios({
      method: "post",
      url: url,
      data: form,
      headers: header,
    });
  },
  connectBzbsDelete: function (url, token, data) {
    var header = {
      "Content-Type": "application/json",
    };

    if (token != null) {
      header.Authorization = "Bearer " + token;
    }
    if (this.language() == "th") {
      header.Language = this.language();
    }
    return axios({
      method: "delete",
      url: url,
      params: data,
      headers: header,
    });
  },
  connectBzbsPostBody: function (url, token, data) {
    var header = {
      "Content-Type": "application/json",
    };

    if (token != null) {
      header.Authorization = "Bearer " + token;
    }
    if (this.language() == "th") {
      header.Language = this.language();
    }
    return axios({
      method: "post",
      url: url,
      data,
      headers: header,
    });
  },
  connectBzbsDeleteBody: function (url, token, data) {
    var header = {
      "Content-Type": "application/json",
    };

    if (token != null) {
      header.Authorization = "Bearer " + token;
    }
    if (this.language() == "th") {
      header.Language = this.language();
    }
    return axios({
      method: "delete",
      url: url,
      data,
      headers: header,
    });
  },
  connectBzbsPutBody: function (url, token, data) {
    var header = {
      "Content-Type": "application/json",
    };

    if (token != null) {
      header.Authorization = "token " + token;
    }
    if (this.language() == "th") {
      header.Language = this.language();
    }
    return axios({
      method: "put",
      url: url,
      data,
      headers: header,
    });
  },
  /**
   * Convert data to array of FormData
   * @param {Array|Dictionary} arr
   */
  arrToFormData: function (arr) {
    var form = new FormData();
    for (var i in arr) {
      form.append(i, arr[i]);
    }
    return form;
  },

  /**
     * Connect Buzzebees GET Blob
     * @param {string} url string url

     * @return Promise
     */
  connectBzbsGetBlob: function (url) {
    return axios({
      method: "get",
      url: url,
    });
  },
  connectBzbsGetAccessAllow: function (url, data) {
    if (data == null) {
      data = {};
    }
    var header = {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
      "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
    };
    return axios({
      method: "get",
      url: url,
      params: data,
      headers: header,
    });
  },
  paramsArray: function (obj, string) {
    let paramsArray = "";
    if (_.has(obj, string)) {
      _.forEach(obj[string], (item) => {
        if (item != "") paramsArray += `${string}=${item}&`;
      });
      paramsArray = paramsArray.slice(0, -1);
    }

    return paramsArray;
  },
};
