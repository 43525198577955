<template>
  <div class="auth_unauthorized">
    <a-result status="error" :title="textauth">
      <template #icon>
        <img :src="imgunauthor" />
      </template>
    </a-result>
  </div>
</template>
<script>
import { config } from "@/config/bzbsconfig.js";
import CommonMixin from "@/mixin/CommonMixin";

export default {
  name: "AuthUnauthorized",
  mixins: [CommonMixin],
  data() {
    return {
      imgunauthor: config.themeUrl + "/img/unauthorized.svg",
      textauth: "You don't have Permission for this page",
    };
  },
  created() {
    this.handleLoading(false);
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.auth_unauthorized {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  transform: translate(-50%, -50%);
  /* @include transform(translate(-50%, -50%)); */
}
</style>
