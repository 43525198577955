<template>
  <div class="page-404 py-4 text-center">
    <div class="logo">
      <router-link to="/">
        <img src="@/assets/images/logo.svg" alt=""
      /></router-link>
    </div>
    <div class="image-404">
      <img src="@/assets/images/404.svg" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  name: "PageNotFound",
  data() {
    return {};
  },
  created() {
    this.handleLoading(false);
  },
  methods: {
    handleLoading(value) {
      this.$store.commit("Loading", value);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/_mixin.scss";
.page-404 {
  .logo {
    margin: 20px 0 40px;
    img {
      width: 100px;
    }
  }
  .image-404 {
    img {
      max-width: 800px;
    }
  }
}
</style>
