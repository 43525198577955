import * as Msal from "@azure/msal-browser";
import { config } from "@/config/bzbsconfig.js";
import Cache from "@/helper/Cache";
const myMSAL = new Msal.PublicClientApplication(config.msalConfig);

export default {
  methods: {
    errorApi(error) {
      if (error.response && error.response.status == 401) {
        this.errorUnauthorized();
      } else {
        let newData = {
          status: "99999-99999",
          description: "Is Something Error",
        };
        if (error.response) {
          newData = {
            status: error.response.status,
            description: error.response.statusText,
          };
          if (error.response.data) {
            if (error.response.data.error) {
              newData.error = error.response.data.error[0];
            }
            if (error.response.data.transaction_id) {
              newData.transaction_id = error.response.data.transaction_id;
            }
          }
        }
        setTimeout(() => {
          this.alertData = newData;
          this.modalAlert = {
            id: "error",
            visible: true,
            title: this.mylbl("package_page.modal.title_error"),
          };
          this.isModalResponse = true;
          this.handleLoading(false);
        }, 100);
      }
    },
    errorUnauthorized() {
      let refeshToken = config.refeshToken;
      refeshToken.account = Cache.get("msAccount");
      myMSAL
        .acquireTokenSilent(refeshToken)
        .then((res) => {
          Cache.set("msToken", res.accessToken);
          this.$router.go();
        })
        .catch(() => {
          Cache.remove("msToken");
          this.$router.go();
        });
    },
  },
};
