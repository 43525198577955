import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
Vue.use(Vuex);

/* eslint-disable */
export const store = new Vuex.Store({
  state: {
    auth: [],
    isAppLoad: true,
    isEdit: false,
    managecompany: false,
    manageapp: false,
    objforsave: [],
    localecode: "en",
    azureConfig: null,
  },
  mutations: {
    setAuth(state, auth) {
      state.auth = auth;
    },
    Loading(state, value) {
      state.isAppLoad = value;
    },
    isEdit(state, value) {
      state.isEdit = value;
    },
    managecompany(state, value) {
      state.managecompany = value;
    },
    manageapp(state, value) {
      state.manageapp = value;
    },
    objforsave(state, value) {
      state.objforsave = value;
    },
    setLocalecode(state, value) {
      state.localecode = value;
    },
    AzureConfig(state, value) {
      state.azureConfig = value;
    },
  },
  plugins: [createPersistedState()],
  actions: {
    initial({ commit }) {
      let auth = null;
      if (Account.getAuth()) {
        try {
          auth = Account.getAuth();
        } catch (err) {
          console.log("actions initial", err);
        }
      }
      commit("setAuth", auth);
    },
  },
});
