import Vue from "vue";
import Router from "vue-router";

import LoginInvite from "@/module/Home/LoginInvite";
import AuthUnauthorized from "@/module/Home/Unauthorized";
import Logout from "@/module/Home/Logout.vue";
import Redirectms from "@/module/Home/Redirectms";
import Home from "@/module/Home/Home";
import Cache from "@/helper/Cache";
import PageNotFound from "@/module/PageNotFound/pages/Index";

Vue.use(Router);

function requireAuth(to, from, next) {
  if (Cache.get("msToken")) {
    next("/no-permission");
  } else {
    next();
  }
}

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      component: Home,
      beforeEnter: requireAuth,
    },
    {
      path: "/logout",
      name: "logout",
      component: Logout,
    },
    {
      path: "/logininvite",
      name: "LoginInvite",
      component: LoginInvite,
    },
    {
      path: "/unauthorized",
      name: "unauthorized",
      component: AuthUnauthorized,
    },
    {
      path: "/redirectms",
      name: "Redirectms",
      component: Redirectms,
    },
    {
      path: "/home",
      name: "Home",
      component: Home,
    },
    { path: "/:pathMatch(.*)*", component: PageNotFound },
  ],
});
