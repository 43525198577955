<template>
  <div v-if="isshowBg" class="layout-auth page-layout">
    <div
      class="layout-background"
      :style="{ 'background-image': 'url(' + banner + ')' }"
    ></div>
    <!-- <div v-if="islogininvite" class="layout-content py-5">
      <div class="content-box">
        <div class="icon-add-app"><img :src="logoBzbs" /></div>
        <div class="mt-2 content-title text-center">
          The acceptance process was successful.
        </div>
        <div class="mt-2 content-detail">Please close this window.</div>
        <div>
          <button class="custom-btn-close py-2" @click="closetab">
            {{ mylbl("invite_page.btn.btn_close") }}
          </button>
        </div>
      </div>
    </div> -->
    <div v-if="isShowResultInvite" class="layout-content-error">
      <div class="content-box" style="max-width: 685px">
        <div class="content-title text-center">
          <span v-if="resultInvite.err">
            <h3 class="title-error">
              {{ `${resultInvite.name} (${resultInvite.id})` }}
            </h3>
            <div class="content-error mt-3">
              <span class="font-weight-bold">Transaction ID : </span>
              <span class="text-caption">{{
                resultInvite.transaction_id
              }}</span>
            </div>
            <div class="content-error">
              <span class="font-weight-bold">Error Message : </span>
              <span class="text-caption">{{ resultInvite.content }}</span>
            </div>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import CommonMixin from "@/mixin/CommonMixin";
import Cache from "@/helper/Cache";
import AccountHelper from "@/helper/AccountHelper";
import userAPI from "@/core/Page/AccountAPI";
import TimerHelper from "@/helper/TimerHelper.js";
import { config } from "@/config/bzbsconfig.js";
import * as Msal from "@azure/msal-browser";
const myMSAL = new Msal.PublicClientApplication(config.msalConfig);

export default {
  name: "Login",
  mixins: [CommonMixin],
  data() {
    return {
      defaultLanguage: "Eng",
      productName: "",
      banner: require("@/assets/images/auth/bg-bzb.jpg"),
      logo: require("@/assets/images/LogoPowered.png"),
      logoBzbs: require("@/assets/images/bzbs-logo1.png"),
      isShowResultInvite: false,
      timeHelper: new TimerHelper(),
      desc_line1: "",
      desc_line2: "",
      isshowBg: false,
    };
  },

  created() {
    this.init();
  },
  methods: {
    getLanguageList() {
      return [
        {
          title: this.lbl.language_title.en,
          key: "en",
        },
        {
          title: this.lbl.language_title.th,
          key: "th",
        },
      ];
    },
    beforeCreate() {
      Cache.set("BzbsLocale", "en");
    },
    init() {
      this.handleLoading(true);
      AccountHelper.clearCache();
      let code = this.$route.query.code;
      let resultInvite = Cache.get("resultInvite");
      if (code || resultInvite) {
        if (code) {
          this.callGetUserList();
        }
        if (resultInvite?.err) {
          this.resultInvite = resultInvite;
          Cache.remove("resultInvite");
          Cache.remove("inViteCode");
          this.handleShowError();
        }
      } else {
        console.log("5555");
        this.$router.push({
          name: "unauthorized",
        });
      }

      // let inviteobj = Cache.get("inViteCode");
      // if (inviteobj) {
      //   Cache.remove("inViteCode");
      //   Cache.remove("resultInvite");
      //   this.isshowBg = true;
      //   this.islogininvite = true;
      //   this.handleLoading(false);
      // }
    },
    callGetUserList() {
      let params = {
        code: this.$route.query.code,
      };
      userAPI
        .getValidateUse(params)
        .then((res) => {
          if (res.data && res.data.status_code == 200) {
            this.signinAD();
          }
        })
        .catch((err) => {
          var resultInvite = {
            err: true,
            id: err.response.data.error[0].id,
            name: err.response.data.error[0].code,
            content: err.response.data.error[0].message,
            transaction_id: err.response.data.transaction_id,
          };
          this.resultInvite = resultInvite;
          this.handleShowError();
        });
    },
    handleMenuClick(e) {
      const nowlocale = Vue.$cookies.get("BzbsLocale");
      if (nowlocale == e.key) {
        return;
      }
      this.handleLoading(true);
      Vue.$cookies.set("BzbsLocale", e.key);
      window.location.reload();
    },
    signinAD() {
      Cache.set("msStatus", "signin");

      const logoutRequest = {
        postLogoutRedirectUri: config.msalConfig.auth.redirectUri,
        state: "state&" + window.location.search.substring(1),
      };
      let emailingroup = this.$route.query.invited_email.match(
        /@gmail|@outlook|@hotmail/
      );
      if (emailingroup) {
        Vue.$cookies.set("state", logoutRequest.state);
      } else {
        Cache.remove("state");
      }
      myMSAL.logoutRedirect(logoutRequest);
    },
    goto() {
      if (this.redirectUrlTo) {
        window.location = this.redirectUrlTo;
      } else {
        this.$router.push({ name: "Home" });
      }
    },
    closetab() {
      window.close();
    },
    handleShowError() {
      this.isshowBg = true;
      this.isShowResultInvite = true;
      this.handleLoading(false);
    },
    setRunTimeout: function () {
      this.isExpireTime = false;
      this.timeHelper.stopTimer();
      this.timeHelper.resetTimer();
      this.timeHelper.initialTimer(10 * 1, null, false, false);
      this.timeHelper.runTimer(() => {
        this.setNewRunTimeout();
      });
    },
    setNewRunTimeout: function () {
      this.timeHelper.stopTimer();
      this.timeHelper.resetTimer();
      this.goto();
    },
  },
};
</script>

<style lang="scss">
@import "@/style/_mixin.scss";
.layout-auth {
  min-height: 100vh;
  @include flex();
  @include background-style();
  position: relative;
  background-color: #eef0f8;
  .layout-background {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    @include background-style();
    background-attachment: fixed;
  }
  .layout-content-error {
    font-size: 14px;
    max-width: 100%;
    z-index: 1;
    margin: auto;
    .content-box {
      background-color: $white-primary;
      padding: 25px;
      box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
      border-radius: 6px;
      text-align: center;
      max-width: 300px;
    }
    .text-caption {
      font-size: 14px;
    }
    .title-error {
      color: var(--red-red-6);
    }
  }
}
</style>
