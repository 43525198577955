import Vue from 'vue'
export default {
  /**
   *
   * @method get
   * @method set
   * @method remove
   * @method removePopCache
   *
   */

  get: function (key) {
    
    return Vue.$cookies.get(key) || null
  },
  set: function (key, value) {
    Vue.$cookies.set(key, value)
  },
  remove: function (key) {
    Vue.$cookies.remove(key)
  },
}
