import moment from "moment";
import Cache from "@/helper/Cache.js";
import _ from "lodash";

export default {
  data() {
    return {
      IConAdd: require("@/assets/images/Icon/Icon_Add.svg"),
      IConAddBlue: require("@/assets/images/Icon/Icon_AddBlue.svg"),
      IConEdit: require("@/assets/images/Icon/Icon_Edit.svg"),
      IConActive: require("@/assets/images/Icon/Icon_Active-circle.svg"),
      IConInctive: require("@/assets/images/Icon/Icon_Inctive-circle.svg"),
      IConDelete: require("@/assets/images/Icon/Icon_Delete.svg"),
      IConDeletePrimary: require("@/assets/images/Icon/Icon_Delete-primary.svg"),
      IConManage: require("@/assets/images/Icon/Icon_Manage.svg"),
      IConAngleLeft: require("@/assets/images/Icon/Icon_Angle-left.svg"),
      IConAngleRight: require("@/assets/images/Icon/Icon_Angle-right.svg"),
      IConProduct: require("@/assets/images/Icon/Icon_Product.svg"),
      IConCopy: require("@/assets/images/Icon/Icon_Copy.svg"),
      IConLink: require("@/assets/images/Icon/Icon_Link.svg"),
      IConCompare: require("@/assets/images/Icon/Icon_Compare.svg"),
      IConFeature: require("@/assets/images/Icon/Icon_Feature.svg"),
      IConDataLv: require("@/assets/images/Icon/Icon_Data-Level.svg"),
      IConPackage: require("@/assets/images/Icon/Menu-Package.svg"),
      IConPackageActive: require("@/assets/images/Icon/Menu-Package-Active.svg"),
      IConArrowDown: require("@/assets/images/Icon/Icon-arrow-down.svg"),
      IConBag: require("@/assets/images/Icon/Icon_Bag.svg"),
      setHeightDefualt: 110,
      setWidthDefualt: 32,
      w_Input_350: "350px",
    };
  },
  created() {
    this.getlocalStorage();
  },
  methods: {
    getlocalStorage() {
      var locale = Cache.get("BzbsLocale");
      if (locale != "en" && locale != "th") {
        Cache.set("BzbsLocale", "en");
        locale = "en";
      }
      this.$store.commit("setLocalecode", locale);
      if (localStorage.getItem("setJsonLocal_" + locale)) {
        this.lbl = JSON.parse(localStorage.getItem("setJsonLocal_" + locale));
      }
    },
    truncate(num, places) {
      return Math.trunc(num * Math.pow(10, places)) / Math.pow(10, places);
    },
    handleLoading(value) {
      this.$store.commit("Loading", value);
    },
    // for ant select search option
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    formatDate(timestamp, format = "DD-MMM-YYYY") {
      return moment.unix(timestamp).format(format);
    },
    formatDateString(
      dateString,
      format = "DD  MMM  YYYY - hh:mm A",
      calHours = 7
    ) {
      return moment(dateString).add(calHours, "hours").format(format);
    },
    formHasError(fieldsError) {
      return Object.keys(fieldsError).some((field) => fieldsError[field]);
    },
    successResponse(text, router) {
      this.handleLoading(false);
      var texttitle = "";
      if (text) {
        texttitle = text;
      } else {
        texttitle = this.lbl.main.success_popup;
      }
      this.$success({
        title: texttitle,
        content: "",
        centered: true,
        autoFocusButton: null,
        closable: true,
        onOk: () => {
          if (typeof router !== "undefined") {
            if (!(typeof router === "boolean")) {
              this.$router.push(router);
              this.handleLoading(true);
            }
          } else {
            this.$router.go();
            this.handleLoading(true);
          }
        },
      });
    },
    parseInt(value) {
      if (typeof value === "string") {
        return parseInt(value);
      } else if (typeof value === "number") {
        return value;
      }
    },
    mylbl(path) {
      const myValue = _.get(
        this.lbl,
        path,
        " 'lbl." + path + "'" + " not found"
      );
      return myValue;
    },
    goToPage(page) {
      this.$router.push(page);
    },
    goToNewTab(page, query_id) {
      let routeData = this.$router.resolve({
        name: page,
        query: { id: query_id },
      });
      window.open(routeData.href, "_blank");
    },
    boxDetailHeight() {
      let boxdetail = document.querySelector("#box-detail");
      let boxdetailheight = boxdetail.offsetHeight;
      return boxdetailheight;
    },
    boxDetailWidth() {
      let boxdetail = document.querySelector("#box-detail");
      let boxdetailwidth = boxdetail.offsetWidth;
      return boxdetailwidth;
    },
    unFixedColumn(columns) {
      var last = columns[columns.length - 1];
      last = Object.keys(last)
        .filter((key) => !key.includes("fixed"))
        .reduce((obj, key) => {
          return Object.assign(obj, {
            [key]: last[key],
          });
        }, {});
      return last;
    },
    fixedColumn(columns) {
      var last = columns[columns.length - 1];
      last.fixed = "right";
      return last;
    },
    dateToTimestamp(date) {
      date = moment(date).format("X");
      return date;
    },
  },
  filters: {
    commaNumber: function (value) {
      if (value) {
        return value.toLocaleString();
      }
      return 0;
    },
  },
};
