<template>
  <div></div>
</template>

<script>
import Cache from "@/helper/Cache";
import AccountHelper from "@/helper/AccountHelper";
import Locale from "@/helper/locale/locale.js";

export default {
  created() {
    this.init();
  },
  methods: {
    init() {
      AccountHelper.clearCache();
      Cache.set("BzbsLocale", "en");
      Locale.getLbl();
      AccountHelper.initial();
      setTimeout(() => {
        Cache.set("msStatus", "signin");
        this.$router.push({ name: "Redirectms" });
      }, 500);
    },
  },
};
</script>

<style lang="scss">
@import "@/style/_mixin.scss";
</style>
