<template>
  <div class="box-loader" :class="{ loaded: !isShow }">
    <div class="wrapper-loaading">
      <span class="loader"></span>
      <p v-if="text">{{ text }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Loading",
  props: {
    text: String,
    isShow: Boolean,
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/_mixin.scss";
.loader-svg {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  z-index: 99999;
  &.loaded {
    display: none;
  }
  .ant-spin-spinning {
    margin: auto;
    display: block;
    position: absolute !important;
    left: 0;
    right: 0;
    top: 50%;
  }
}
</style>
